const PrivacyPolicy = () => {
  return (
    <main className="flex flex-1 justify-center">
      <div className="policy flex flex-col w-full my-8 p-4 px-8 max-w-3xl [&>p]:mt-1 [&>p]:font-light [&>h2]:text-2xl [&>h2]:font-bold [&>h2]:mt-12 [&>h3]:text-lg [&>h3]:mt-4 [&>h3]:underline">
        <h1 className="text-3xl font-bold">隱私政策</h1>
        <p style={{ margin: '1.6rem 0' }}>最後修訂日期：2024 年 10 月 11 日</p>
      </div>
    </main>
  )
}

export default PrivacyPolicy;