import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import React, { useEffect, useState } from 'react';
import { ClientType, UserModel } from './utils/interfaces';
import { databaseNames, storageKeys } from './utils/constants';
import Landing from './pages/Landing';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Footer from './components/Footer';
import { config } from './utils/config';
import Settings from './pages/Settings';
import Logging from './pages/Logging';
import PageNotFound from './pages/PageNotFound';
import Contact from './pages/Contact';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

export interface AppProps {
  user: UserModel | null | undefined
  setUser: React.Dispatch<React.SetStateAction<UserModel | null | undefined>>
  mode: ClientType
  setMode: React.Dispatch<React.SetStateAction<ClientType>>
}

function App() {

  const [user, setUser] = useState<UserModel | null | undefined>(undefined);
  const [mode, setMode] = useState<ClientType>(localStorage.getItem(storageKeys.mode) as ClientType || 'applicant');
  // props
  const props = {
    user, setUser,
    mode, setMode,
  }

  // Load current user
  useEffect(() => {
    (async () => {
      // check if there's current user
      const currUserId = localStorage.getItem(storageKeys.id);
      if (currUserId) {
        try {
          const data = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${currUserId}`)).json();
          setUser(data);
        } catch (error) {}
      } else {
        setUser(null);
      }
      // check current mode
      const currMode = localStorage.getItem(storageKeys.mode);
      if (!currMode) {
        localStorage.setItem(storageKeys.mode, 'applicant');
      }
    })();
  }, []);
    
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar {...props} />
        <Routes>
          {/* auth */}
          <Route path='/login' element={<SignIn {...props} />} />

          {/* main pages */}
          <Route path='/' element={user ? <Home user={user} setUser={setUser} mode={mode} setMode={setMode} /> : user === null ? <Landing {...props} /> : <Logging />} />
          <Route path='/jobs/:jobId' element={<Home user={user} setUser={setUser} mode={mode} setMode={setMode} />} />
          <Route path='/apply/:jobId' element={<Home user={user} setUser={setUser} mode={mode} setMode={setMode} />} />

          {/* settings */}
          <Route path='/settings/info' element={<Settings {...props} />} />
          <Route path='/settings/account' element={<Settings {...props} />} />
          {/* <Route path='/settings/payments' element={<Settings {...props} />} /> */}

          {/* contacts */}
          <Route path='/feedback' element={<Contact {...props} />} />

          {/* policies */}
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          
          <Route path='/page-not-found' element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
