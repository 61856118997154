import React, { useEffect, useState } from "react";
import Jobs from "./OrgDashboard_components/Jobs";
import { AppProps } from "../App";
import Loading from "./Loading";
import { JobModel, UserModel } from "../utils/interfaces";
import { randString } from "../utils/functions";
import { config } from "../utils/config";
import { databaseNames } from "../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Job from "./OrgDashboard_components/Job";

type MainContentType = 'jobs' | 'job' | 'applicant' | 'message' | 'ratings'

const MainContentTypeNames = {
  'jobs': '你發布的工作',
  'job': '工作資訊',
  'applicant': '申請人',
  'message': '訊息',
  'ratings': '企業評價',
}

export interface OrgDashboardProps extends AppProps {
  mainContentType: MainContentType
}

const OrgDashboard = (props: AppProps) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);

  const [showNewJobForm, setShowNewJobForm] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [address, setAddress] = useState('');
  const [reward, setReward] = useState('');
  const [applicantsAmount, setApplicantsAmount] = useState('1');
  const [detail, setDetail] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const [mainContentType, setMainContentType] = useState<MainContentType>('jobs');
  const dashboardProps = {
    ...props,
    mainContentType,
  }

  // check user organization setup
  useEffect(() => {
    if (!props.user?.business?.name) { // organization not setup

    }
  }, []);

  // check route type
  useEffect(() => {
    // job details
    if (location.pathname === '/') {
      setMainContentType('jobs');
    } else if (location.pathname.startsWith('/jobs/')) {
      setMainContentType('job');
    }
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.new-job-button') && !target.closest('.new-job-form')) {
      setShowNewJobForm(false);
    }
  }

  // post new job
  const postNewJob = async () => {
    setIsPosting(true);
    const newJob: JobModel = {
      id: randString(16),
      title: jobTitle,
      description: detail,
      reward: reward,
      time: time,
      duration: duration,
      address: address,
      applicants: [],
      applicantsAmount: parseInt(applicantsAmount),
      postedDate: new Date().toISOString(),
      poster: props.user?.id || '',
      posterName: props.user?.business?.name || '',
      type: 'all',
      area: 'all',
    };
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newJob) });
    setIsPosting(false);
    setShowNewJobForm(false);
    navigate(`/jobs/${newJob.id}`);
  }

  const SidebarContent = <>
    <button onClick={() => {
      setShowNewJobForm(true);
      setShowSidebar(false);
    }} className="p-2 new-job-button bg-primary text-white">發布新工作機會</button>
    <button onClick={() => {
      navigate('/');
      setShowSidebar(false);
    }} className={`p-2 hover:bg-gray-200 ${mainContentType === 'jobs' ? 'bg-gray-200' : ''}`}>所有工作</button>
    {/* <button onClick={() => {}} className={`hover:bg-gray-200 ${mainContentType === 'message' ? 'bg-gray-200' : ''}`}>訊息</button> */ }
  {/* <button onClick={() => {}} className={`hover:bg-gray-200 ${mainContentType === 'ratings' ? 'bg-gray-200' : ''}`}>公司評價</button> */ }
  </>

  return (
  <>
    <div className="w-full h-full flex flex-1 overflow-hidden">
      <aside className="sticky top-0 self-start h-full z-0 hidden md:flex flex-col w-80 rounded-2xl bg-gray-100 p-4 *:text-sm *:mt-2 *:p-2 *:rounded-lg">
        {SidebarContent}
      </aside>
      <main className="flex-1 md:ml-8 px-2 md:px-4 md:py-4 flex flex-col h-full w-full md:w-auto overflow-auto">
        <div onClick={() => setShowSidebar(true)} className="md:hidden flex py-1"><button className="sidebar-button flex items-center justify-center h-10 w-10 rounded-full hover:bg-gray-100"><i className="fa-solid fa-bars text-lg" /></button></div>
        {(mainContentType !== 'job' && mainContentType !== 'applicant') && <h1 className="text-3xl font-bold md:mt-0 mt-4">{MainContentTypeNames[mainContentType]}</h1>}
        {mainContentType === 'jobs' ?
          <Jobs setShowNewJobForm={setShowNewJobForm} {...dashboardProps} />
          : mainContentType === 'job' && props.user !== undefined ?
            <Job id={location.pathname.slice(6)} {...dashboardProps} />
            : <></>}
      </main>
    </div>

    {/* new job form */}
    {(props.user && showNewJobForm) && (
      isValidBusiness(props.user) ?
        <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
          <div className="overflow-auto new-job-form w-full max-w-xl rounded-2xl shadow-xl p-4 px-8 bg-white" style={{ maxHeight: '80vh' }}>
            <div className="flex justify-between">
              <h1 className="text-xl">新工作</h1>
              <i onClick={() => setShowNewJobForm(false)} className="fa-solid fa-times h-7 w-7 flex items-center justify-center hover:bg-gray-100 rounded-full cursor-pointer" />
            </div>
            <div className="text-sm">
              <div className="flex flex-col mt-4">
                <label className="text-gray-500 m-1">職位名稱</label>
                <input placeholder="例：搬貨員、服務生" className="rounded-lg p-2 border outline-none focus:border-black" value={jobTitle} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setJobTitle(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1 flex items-center">地址<span onClick={() => setAddress(props.user?.business?.address || '')} className="block ml-2 p-1 px-2 rounded-lg cursor-pointer bg-gray-100 text-black text-xs">同公司地址</span></label>
                <input className={`rounded-lg p-2 border outline-none focus:border-black`} value={address} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1">工作期間</label>
                <input placeholder="例：7/1～7/14、12/10～12/15" className="rounded-lg p-2 border outline-none focus:border-black" value={duration} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setDuration(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1">時間</label>
                <input placeholder="例：9:00～15:00" className="rounded-lg p-2 border outline-none focus:border-black" value={time} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setTime(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1">報酬</label>
                <input placeholder="例：$1000/天、總共 $10000" className="rounded-lg p-2 border outline-none focus:border-black" value={reward} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setReward(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1">需求人數</label>
                <input placeholder="例：1、3、10" className={`rounded-lg p-2 border outline-none focus:border-black`} value={applicantsAmount} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setApplicantsAmount(e.target.value)} />
              </div>
              <div className="flex flex-col mt-2">
                <label className="text-gray-500 m-1">工作內容</label>
                <textarea className="rounded-lg p-2 border resize-y outline-none focus:border-black" value={detail} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDetail(e.target.value)} />
              </div>
            </div>
            <div className="flex justify-end">
              <button onClick={postNewJob} className="flex items-center justify-center p-2 w-24 rounded-lg bg-primary text-white mt-8 disabled:bg-gray-300" disabled={jobTitle === '' || time === '' || duration === '' || reward === '' || applicantsAmount === '' || detail === '' || isPosting}>{isPosting ? <Loading /> : '發布'}</button>
            </div>
          </div>
        </div>
        :
        <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
          <div className="invalid-business-alert rounded-xl bg-white shadow-lg p-4 px-8 w-full max-w-xl">
            <h1 className="text-xl font-bold">完成企業資料</h1>
            <p className="font-light mt-4">需要先完成公司或商店的基本資料設定，才能發布工作機會。</p>
            <div className="mt-8 flex justify-center"><button onClick={() => navigate('/settings/info')} className="text-sm bg-primary p-2 px-8 rounded-lg text-white">前往設定</button></div>
          </div>
        </div>
    )}

    {/* mobile sidebar */}
    <div className="sidebar bg-gray-100  *:text-sm *:mt-2 *:rounded-lg rounded-2xl p-4 fixed z-20 md:hidden flex flex-col max-w-96" style={{ transition: 'all .3s', top: '16px', height: 'calc(100vh - 32px)', width: '84vw', left: showSidebar ? '16px' : '-100%', boxShadow: showSidebar ? '0px 0px 1000px 500px #0004' : 'none' }}>
      <div className="flex justify-end"><i onClick={() => setShowSidebar(false)} className="fa-solid fa-times h-8 w-8 flex items-center cursor-pointer justify-center rounded-full hover:bg-gray-200" /></div>
      {SidebarContent}
    </div>
  </>
)
}

export const isValidBusiness = (user: UserModel) => {
  return (
    user.business?.phoneNumber &&
    user.business.bio &&
    user.business.name &&
    user.business.picture &&
    user.business.address
  )
}

export default OrgDashboard;