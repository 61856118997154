import React, { useEffect, useState } from "react";
import { JobModel, UserModel } from "../../utils/interfaces";
import Loading from "../Loading";
import { config } from "../../utils/config";
import { databaseNames } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { OrgDashboardProps } from "../OrgDashboard";
import { getAge, getFullName, sendEmail } from "../../utils/functions";
import ApplicantInfo from "../ApplicantInfo";

interface Props extends OrgDashboardProps {
  id: string
}

const Job = (props: Props) => {

  const navigate = useNavigate();

  const [job, setJob] = useState<undefined | null | JobModel>(undefined);
  const [applicants, setApplicants] = useState<UserModel[]>([]);
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});
  const [newTitle, setNewTitle] = useState('');
  const [newTime, setNewTime] = useState('');
  const [newDuration, setNewDuration] = useState('');
  const [newReward, setNewReward] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newApplicantsAmount, setNewApplicantsAmount] = useState('');
  const [newDetail, setNewDetail] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showAcceptedAlert, setShowAcceptedAlert] = useState(false);

  const [displayingApplicant, setDisplayingApplicant] = useState<null | UserModel>(null);

  // load job from database
  useEffect(() => {
    (async () => {
      try {
        const data = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}&id=${props.id}`)).json();
        console.log(props.user?.id, data.poster)
        if (props.user && data.poster !== props.user?.id) {
          navigate('/page-not-found');
          return;
        }
        setJob(data);
      } catch (error) {
        setJob(null);
      }
    })();
  }, [props.id, props.user]);

  // redirect if job not exists or user is not poster
  useEffect(() => {
    if (job === null) {
      navigate('/page-not-found');
      return;
    } else if (job) {
      // load applicants
      (async () => {
        try {
          const appData = [];
          let newUrls: { [key: string]: string } = {};
          for (const id of job.applicants) {
            const userData: UserModel = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${id}`, { headers: { 'Content-Type': 'application/json' } })).json();
            appData.push(userData);
            // load image
            if (userData.picture) {
              const imageData = (await (await fetch(`${config.apiUrl}/s3?bucket=${databaseNames.bucket}&file_name=${userData.picture}`)).json());
              newUrls[userData.id] = `data:${imageData.content_type};base64,${imageData.data}`;
            }
          }
          setImageUrls(newUrls);
          setApplicants(appData);
        } catch (error) {
          console.log(error)
        }
      })();
    }
  }, [job]);

  useEffect(() => {
    if (isEditing && job) {
      setNewTitle(job.title);
      setNewTime(job.time);
      setNewDuration(job.duration);
      setNewReward(job.reward);
      setNewAddress(job.address || '');
      setNewApplicantsAmount(`${job.applicantsAmount}`);
      setNewDetail(job.description);
    }
  }, [isEditing]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.delete-button') && !target.closest('.delete-alert')) {
      setShowDeleteAlert(false);
    }
    if (!target.closest('.applicant-alert') && !target.closest('.applicant-button')) {
      setDisplayingApplicant(null);
    }
  }

  // save edit
  const saveEdit = async () => {
    if (!job) return;
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}&id=${job?.id}`, {
      method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
        title: newTitle,
        time: newTime,
        duration: newDuration,
        reward: newReward,
        address: newAddress,
        applicantsAmount: parseInt(newApplicantsAmount),
        description: newDetail,
      })
    });
    setJob({
      ...job,
      title: newTitle,
      time: newTime,
      duration: newDuration,
      reward: newReward,
      address: newAddress,
      applicantsAmount: parseInt(newApplicantsAmount),
      description: newDetail,
    });
    setIsEditing(false);
  }

  // delete job
  const deleteJob = async () => {
    if (!job) return;
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}&id=${job.id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } });
    setShowDeleteAlert(false);
    // send email to applicants
    const message = `
親愛的應徵者，很遺憾地通知你，${job.posterName} 發布的 ${job.title} 職位已被撤銷，並不再開放申請。

我們了解這可能帶來不便，我們鼓勵你繼續關注我們平台上的其他機會。我們持續與多家企業合作，提供更多的工作機會。

你可以繼續瀏覽其他職位。如果您有任何疑問或需要協助，請隨時與我們聯繫。

感謝您的理解與支持！

網址：https://www.leedto.com

Leedto 團隊
info@leedto.com
`;
    await sendEmail(job.applicants, '工作更新通知：您申請的職位已被撤銷', message);

    navigate('/');
  }

  // accept applicant
  const acceptApplicant = async (applicant: UserModel) => {
    if (!job) return;
    setShowAcceptedAlert(true);
    const newJob: JobModel = { ...job, accepted: [...(job.accepted || []), applicant.id] };
    setJob(newJob);
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newJob) });
    // send email to applicant
    const message = `
Hi，${applicant.firstName}，你在 Leedto 上的工作申請已被接受，以下是接下來的步驟和工作詳情：

工作名稱： ${job.title}
公司名稱： ${job.posterName}
工作地點： ${job.address}
工作期間： ${job.duration}
工作時間： ${job.time}

如果有任何疑問，請隨時與我們聯絡。

再次感謝您選擇 Leedto，我們期待與你一同展開新挑戰！

網址：https://www.leedto.com

Leedto 團隊
info@leedto.com
`;
    await sendEmail([applicant.email], '您的工作申請已被接受', message);
  }

  return (
    <>
      {(job === undefined) ? <div className="flex-1 flex items-center justify-center">
        <Loading solid />
      </div>
        : job && props.user ? <>
          {/* header */}
          <div className="flex items-center">
            <i onClick={() => navigate('/')} className="fa-solid fa-arrow-left h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full cursor-pointer" />
            <div className="text-3xl font-bold ml-8">工作資訊</div>
          </div>
          <div className="flex justify-end *:ml-8">
            {isEditing ? <>
              <button onClick={() => setIsEditing(false)} className="rounded-xl p-1 px-4 hover:bg-gray-100">取消</button>
              <button onClick={saveEdit} className="rounded-xl p-1 px-4 text-white bg-primary">儲存</button>
            </> : <>
              <button onClick={() => setIsEditing(true)} className="rounded-xl p-1 px-4 hover:bg-gray-100">編輯</button>
              <button onClick={() => setShowDeleteAlert(true)} className="delete-button rounded-xl p-1 px-4 text-white bg-red-500">刪除</button>
            </>}
          </div>
          {/* info */}
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">職位名稱</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newTitle} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewTitle(e.target.value)} />
              : <div className="m-2 text-lg">{job.title}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">地址</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newAddress} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewAddress(e.target.value)} />
              : <div className="m-2 text-lg">{job.address}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">時間</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newTime} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewTime(e.target.value)} />
              : <div className="m-2 text-lg">{job.time}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">工作期間</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newDuration} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewDuration(e.target.value)} />
              : <div className="m-2 text-lg">{job.duration}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">報酬</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newReward} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewReward(e.target.value)} />
              : <div className="m-2 text-lg">{job.reward}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">人數</label>
            {isEditing ?
              <input className="border mr-2 p-2 rounded-lg outline-none focus:border-black" value={newApplicantsAmount} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewApplicantsAmount(e.target.value)} />
              : <div className="m-2 text-lg">{(job.accepted || []).length} / {job.applicantsAmount}</div>}
          </div>
          <div className="mt-4 flex flex-col">
            <label className="text-sm text-gray-400 m-1">工作內容</label>
            {isEditing ?
              <textarea className="border mr-2 p-2 rounded-lg outline-none focus:border-black resize-y" value={newDetail} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewDetail(e.target.value)} />
              : <div className="m-2 text-lg whitespace-pre-wrap">{job.description}</div>}
          </div>
          {/* applicants */}
          <h2 className="mt-8 text-2xl font-bold">應徵者</h2>
          {job.applicants.length === 0 ? <div className="m-2">
            <p className="font-light">目前還沒有人應徵這份工作</p>
          </div> : <div className="mt-2">
            {applicants.map((applicant, i) => <div className="flex items-center">
              <div onClick={() => setDisplayingApplicant(applicant)} className="applicant-button flex-1 cursor-pointer flex items-stretch w-full rounded-lg hover:bg-gray-100 p-4">
                {/* picture */}
                <div className="h-10 w-10 overflow-hidden rounded-full text-white text-sm flex items-center justify-center bg-neutral-800">
                  {imageUrls[applicant.id] ? <img className="w-full h-full" src={imageUrls[applicant.id]} /> : applicant.firstName[0]}
                </div>
                {/* info */}
                <div className="ml-4 flex-1">
                  <div>{getFullName(applicant.firstName, applicant.lastName)}</div>
                  <div className="text-sm text-gray-400">{getAge(applicant.personalInfo?.birthday || '')} 歲</div>
                  <div className="text-sm text-gray-400">{applicant.personalInfo?.gender}</div>
                </div>
              </div>
              <div className="flex items-center ml-4">
                {job.accepted?.includes(applicant.id) ?
                  <div className="text-gray-400 text-sm">已接受</div>
                  :
                  <button onClick={() => acceptApplicant(applicant)} className="text-white bg-primary text-sm rounded-lg p-2 px-6">接受</button>}
              </div>
            </div>)}
          </div>}
        </>
          : <></>}

      {/* delete alert */}
      {showDeleteAlert && <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
        <div className="delete-alert bg-white p-4 px-8 rounded-xl w-full max-w-md shadow-lg">
          <h1 className="font-bold text-xl">刪除工作</h1>
          <p className="mt-4">取消發布，我們將傳送訊息提醒應徵者該職位已被撤銷。</p>
          <div className="flex justify-end mt-8">
            <button onClick={deleteJob} className="text-sm rounded-lg p-2 px-8 bg-red-500 text-white">刪除</button>
            <button onClick={() => setShowDeleteAlert(false)} className="text-sm rounded-lg p-2 px-8 bg-gray-100 ml-4">取消</button>
          </div>
        </div>
      </div>}

      {/* display applicant */}
      {displayingApplicant && <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
        <ApplicantInfo imageUrl={imageUrls[displayingApplicant.id] || ''} applicant={displayingApplicant} setDisplayApplicant={setDisplayingApplicant} {...props} />
      </div>}

      {/* accepted alert */}
      {showAcceptedAlert && <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
        <div className="delete-alert bg-white p-4 px-8 rounded-xl w-full max-w-md shadow-lg">
          <h1 className="font-bold text-xl">已接受工作申請</h1>
          <p className="mt-4">我們將會發送訊息提醒應徵者準時上工。</p>
          <div className="flex justify-end mt-8">
            <button onClick={() => setShowAcceptedAlert(false)} className="text-sm rounded-lg p-2 px-8 bg-primary text-white ml-4">好</button>
          </div>
        </div>
      </div>}
    </>
  )
}

export default Job;
