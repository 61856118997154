import './style.css';

const Loading = ({ solid }: { solid?: boolean }) => {
  return (
    <div className="flex p-2">
      <div className={`loading-dot${solid ? '' : ' opacity'} rounded-full w-2 h-2 bg-white mx-0.5`} />
      <div className={`loading-dot${solid ? '' : ' opacity'} rounded-full w-2 h-2 bg-white mx-0.5`} style={{ animationDelay: '.4s' }} />
      <div className={`loading-dot${solid ? '' : ' opacity'} rounded-full w-2 h-2 bg-white mx-0.5`} style={{ animationDelay: '.8s' }} />
    </div>
  )
}

export default Loading;