import { useEffect, useState } from "react";
import { AppProps } from "../App";
import { sendEmail } from "../utils/functions";

const Contact = (props: AppProps) => {

  const [feedback, setFeedback] = useState('');
  const [showSentAlert, setShowSentAlert] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.send-button') && !target.closest('.sent-alert')) {
      setShowSentAlert(false);
    }
  }

  const sendFeedback = async () => {
    setShowSentAlert(true);
    await sendEmail(['info@leedto.com'], '使用者回饋', feedback);
    setFeedback('');
  }

  return (
    <>
      <div className="w-full flex-1 flex flex-col items-center">
        <div className="flex flex-col items-center w-full max-w-3xl p-4 *:w-full">
          <h1 className="mt-8 text-3xl font-bold">問題與改善</h1>
          <p className="mt-4">我們重視每個使用者的意見，並努力提供最好的服務，讓我們知道如何幫助你！</p>
          <textarea rows={8} placeholder="填寫你的問題、回饋或我們可以改善的地方，我們將會盡快回覆你..." className="font-light text-sm mt-4 rounded-lg p-2 outline-none resize-y border focus:border-black" value={feedback} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.target.value)} />
          <div className="flex mt-8"><button onClick={sendFeedback} className="send-button p-2 px-8 rounded-lg bg-primary text-white disabled:bg-gray-300" disabled={feedback.length === 0}>提交</button></div>
        </div>
      </div>

      {/* sent alert */}
      {showSentAlert && <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
        <div className="sent-alert bg-white p-4 px-8 rounded-xl w-full max-w-md shadow-lg">
          <h1 className="font-bold text-xl">訊息已送出</h1>
          <p className="mt-4">非常感謝你的回饋，我們將會盡快回復你。</p>
          <div className="flex justify-end mt-8">
            <button onClick={() => setShowSentAlert(false)} className="text-sm rounded-lg p-2 px-8 bg-primary text-white ml-4">關閉</button>
          </div>
        </div>
      </div>}
    </>
  )
}

export default Contact;