import { useNavigate } from "react-router-dom";
import { AppProps } from "../App";


const Landing = (props: AppProps) => {

  const navigate = useNavigate();

  return (
    <div>
      {/* main value proposition for both companies and candidates */}
      <section className="p-8 py-16 md:px-16 flex flex-col items-center">
        <h1 className="mt-16 mx-4 text-center text-5xl font-bold">即時的業務幫手 vs 立即的工作機會</h1>
        <p className="my-8 text-lg text-center">公司透過低成本的合約雇用即時的幫手。而申請者投入短期的工作，並立即獲得回報。</p>
        <button onClick={() => navigate('/login')} className="text-white bg-primary rounded-lg py-2 px-8 mt-4">立即開始</button>
      </section>

      {/* how to use */}
      <section className="p-8 py-16 md:px-16 flex flex-col items-center">
        <h1 className="text-4xl font-bold">如何使用</h1>
        <div className="flex flex-col md:flex-row w-full items-stretch justify-between mt-8">
          <div className="flex flex-1 flex-col items-center p-4 mt-8">
            <div className="w-8 h-8 font-bold flex items-center justify-center rounded-full aspect-square bg-gray-300 text-white">1</div>
            <div className="w-2/3 aspect-square flex items-center max-w-20 mt-8">
              <img className="w-full" src="./images/step1.png" alt="" />
            </div>
            <div className="text-center mt-8 font-semibold">發布工作機會</div>
            <div className="text-center mt-4">企業可以發布臨時工作需求，尋找急需的人才，為求職者提供短期工作機會。</div>
          </div>
          <div className="flex flex-1 flex-col items-center p-4 mt-8">
            <div className="w-8 h-8 font-bold flex items-center justify-center rounded-full aspect-square bg-gray-300 text-white">2</div>
            <div className="w-2/3 aspect-square flex items-center max-w-20 mt-8">
              <img className="w-full" src="./images/step2.png" alt="" />
            </div>
            <div className="text-center mt-8 font-semibold">找尋工作</div>
            <div className="text-center mt-4">應徵者瀏覽並篩選適合的工作機會，找到最符合技能和需求的短期工作。</div>
          </div>
          <div className="flex flex-1 flex-col items-center p-4 mt-8">
            <div className="w-8 h-8 font-bold flex items-center justify-center rounded-full aspect-square bg-gray-300 text-white">3</div>
            <div className="w-2/3 aspect-square flex items-center max-w-20 mt-8">
              <img className="w-full" src="./images/step3.png" alt="" />
            </div>
            <div className="text-center mt-8 font-semibold">開始合作</div>
            <div className="text-center mt-4">雙方開始合作，給予對方即時的幫助。</div>
          </div>
          {/* <div className="flex flex-1 flex-col items-center p-4 mt-8">
            <div className="w-8 h-8 font-bold flex items-center justify-center rounded-full aspect-square bg-gray-300 text-white">4</div>
            <div className="w-2/3 aspect-square flex items-center max-w-20 mt-8">
              <img className="w-full" src="./images/step4.png" alt="" />
            </div>
            <div className="text-center mt-8 font-semibold">互相評價</div>
            <div className="text-center mt-4">在合作結束後，雙方可以進行評價。若對彼此的表現滿意，還能選擇繼續合作，建立長期的關係。</div>
          </div> */}
        </div>
      </section>

      {/* benefits of the new freelancing model */}
      <section className="p-8 py-16 md:px-16 flex flex-col items-center">
        <h1 className="text-4xl font-bold">優勢</h1>
        <div className="w-full flex flex-col md:flex-row md:items-stretch">
          <div className="rounded-xl p-6 flex-1 border-2 border-gray-200 my-8">
            <div className="flex justify-center py-4"><i className="fa-solid fa-building-user text-6xl text-orange-300/50" /></div>
            <h2 className="text-center text-2xl py-4">企業</h2>
            <ul className="mt-8 *:my-4">
              <li>1. <span className="font-bold">成本效益</span>：與正式員工相比，以較低的價格雇用臨時員工，同時擁有你所需的人力</li>
              <li>2. <span className="font-bold">沒有長期承諾</span>：雇用短期合約的自由工作者，不需承擔長期聘用的壓力</li>
              <li>3. <span className="font-bold">即時招聘</span>：在季節性高峰、員工缺勤等緊急情況，提供即時的解決方案</li>
              <li>4. <span className="font-bold">評估潛在員工</span>：比起面試，根據實際工作評估，根據表現情況，將其變為正式員工</li>
              <li>5. <span className="font-bold">幫助他人</span>：為急需工作機會的人提供寶貴的機會，為社會的改變貢獻一分力</li>
            </ul>
          </div>

          <div className="rounded-xl p-6 flex-1 border-2 border-gray-200 my-8 md:ml-16">
            <div className="flex justify-center py-4"><i className="fa-solid fa-clipboard-user text-6xl text-sky-300/50" /></div>
            <h2 className="text-center text-2xl py-4">應徵者</h2>
            <ul className="mt-8 *:my-4">
              <li>1. <span className="font-bold">即時工作機會</span>：為急需一份工作的你，提供一個暫時的解決辦法</li>
              <li>2. <span className="font-bold">短期承諾</span>：持續幾天或幾週的工作，在不了解公司的情況下，不需做出長期承諾</li>
              <li>3. <span className="font-bold">多方面嘗試</span>：用短期工作了解各項職業的實際工作情形</li>
              <li>4. <span className="font-bold">自由</span>：以較低的金錢報酬換來自由，由自己決定何時何地工作</li>
              <li>5. <span className="font-bold">評估公司</span>：根據實際工作內容評估公司，並有機會成為正式員工</li>
            </ul>
          </div>
        </div>
        <button onClick={() => navigate('/login')} className="text-white bg-primary rounded-lg py-2 px-8 my-8">立即開始</button>
      </section>
    </div>
  )
}

export default Landing;