import { useLocation, useNavigate } from "react-router-dom";
import { AppProps } from "../App";
import Logo from "../svgs/Logo";
import { useEffect, useState } from "react";
import MenuPersonInfo from "./MenuPersonInfo";
import { databaseNames, storageKeys } from "../utils/constants";
import { config } from "../utils/config";

const Navbar = (props: AppProps) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  // change title based on route
  useEffect(() => {
    const route = location.pathname;
    if (route.startsWith('/settings')) {
      document.title = '設定 - Leedto';
    } else if (route.startsWith('/jobs/')) {
      document.title = '工作資訊 - Leedto';
    } else if (route.startsWith('/apply/')) {
      document.title = '工作資訊 - Leedto';
    } else if (route === '/feedback') {
      document.title = '問題與回饋 - Leedto';
    } else if (route === '/login') {
      document.title = '登入 - Leedto';
    } else if (route === '/terms-of-service') {
      document.title = '服務條款 - Leedto';
    } else if (route === '/privacy-policy') {
      document.title = '隱私政策 - Leedto';
    } else {
      document.title = 'Leedto';
    }
  }, [location.pathname]);

  // get user image
  useEffect(() => {
    setImageUrl('');
    (async () => {
      if (!props.user?.picture) return;
      try {
        const imageData = (await (await fetch(`${config.apiUrl}/s3?bucket=${databaseNames.bucket}&file_name=${props.user?.picture}`)).json());
        setImageUrl(`data:${imageData.content_type};base64,${imageData.data}`);
      } catch (error) {}
    })();
  }, [props.user]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.menu-button') && !target.closest('.nav-menu')) {
      setShowMenu(false);
    }
  }

  const signOut = () => {
    props.setUser(null);
    navigate('/');
    localStorage.removeItem(storageKeys.id);
    setShowMenu(false);
  }

  const changeMode = (mode: 'applicant' | 'organization') => {
    localStorage.setItem(storageKeys.mode, mode);
    props.setMode(mode);
    setShowMenu(false);
    if (location.pathname !== '/settings/info') {
      navigate('/');
    }
  }

  return (
    <header className="flex items-center justify-between p-4 px-10 sticky top-0 bg-white z-10">
      <div onClick={() => navigate('/')} className="cursor-pointer flex items-center">
        <Logo />
      </div>
      {/* menu or signin */}
      {props.user ? <nav className="flex items-center *:ml-8">
        {/* bell button */}
        {/* <button className="hover:bg-gray-100 w-9 h-9 flex items-center justify-center rounded-full"><i className="fa-regular fa-bell" /></button> */}
        {/* menu */}
        <div className="relative">
          <div onClick={() => setShowMenu(true)} className="menu-button cursor-pointer overflow-hidden rounded-full w-9 h-9 flex items-center justify-center text-sm text-white bg-neutral-800">
            {props.user.picture ? <img className="w-full h-full" src={imageUrl} /> : props.user.firstName[0]}
          </div>
          {showMenu && <menu className="nav-menu hover:*:bg-gray-100 absolute right-0 bg-white shadow-lg rounded-lg border border-gray-100 w-64 py-2" style={{ top: '120%' }}>
            <MenuPersonInfo imageUrl={imageUrl} {...props} />
            <button onClick={() => {
              navigate('/settings/info');
              setShowMenu(false);
            }} className="w-full py-2 px-4 font-light text-sm text-start">設定</button>
            <button onClick={() => changeMode(props.mode === 'applicant' ? 'organization' : 'applicant')} className="w-full py-2 px-4 font-light text-sm text-start">{props.mode === 'applicant' ? '切換到企業模式' : '切換到應徵模式'}</button>
            <button onClick={() => {
              navigate('/feedback');
              setShowMenu(false);
            }} className="w-full py-2 px-4 font-light text-sm text-start">問題回饋</button>
            <button onClick={signOut} className="w-full py-2 px-4 font-light text-sm text-start">登出</button>
          </menu>}
        </div>
      </nav>
      : props.user === null ?
        <button onClick={() => navigate('/login')} className="text-sm hover:text-primary hover:border-primary border-transparent border-2 p-2 px-6 rounded-lg ">登入</button>
      : <></>}
    </header>
  )
}

export default Navbar;