import { useEffect, useState } from "react";
import { AppProps } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../components/Settings/Info";
import Account from "../components/Settings/Account";
import Payments from "../components/Settings/Payments";
import Loading from "../components/Loading";

interface SettingsProps extends AppProps {

}

const Settings = (props: SettingsProps) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [settingContent, setSettingContent] = useState<'info' | 'account' | 'payments'>('info');
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (props.user === null) {
      navigate('/login');
    } else {
      if (location.pathname === '/settings/info') {
        setSettingContent('info');
      } else if (location.pathname === '/settings/account') {
        setSettingContent('account');
      } else if (location.pathname === '/settings/payments') {
        setSettingContent('payments');
      }
    }
  }, [location.pathname, props.user]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.sidebar-button') && !target.closest('.sidebar')) {
      setShowSidebar(false);
    }
  }

  const SidebarContent = <>
    <button onClick={() => {
      navigate('/settings/info');
      setShowSidebar(false);
    }} className={`hover:bg-gray-100 ${settingContent === 'info' ? 'bg-gray-100' : ''}`}>個人資料</button>
    <button onClick={() => {
      navigate('/settings/account');
      setShowSidebar(false);
    }} className={`hover:bg-gray-100 ${settingContent === 'account' ? 'bg-gray-100' : ''}`}>帳號</button>
    {/* <button onClick={() => navigate('/settings/payments')} className={`hover:bg-gray-100 ${settingContent === 'payments' ? 'bg-gray-100' : ''}`}>付款與收款</button> */}
  </>

  return (
    <>
      {(props.user === undefined) ? <div className="flex-1 flex items-center justify-center w-full"><Loading solid /></div>
        : (props.user) ? <div className="flex justify-center w-full flex-1">
          <div className="w-full h-full flex px-4 max-w-5xl">
            <aside className="sticky top-0 self-start h-full z-0 hidden md:flex flex-col w-64 rounded-2xl p-4 *:text-start *:text-sm *:mt-2 *:p-2 *:px-4 *:rounded-lg">
              {SidebarContent}
            </aside>
            {/* settings content */}
            <main className="flex-1 md:ml-8 py-0 md:py-4 h-full w-full md:w-auto overflow-auto">
              <div onClick={() => setShowSidebar(true)} className="md:hidden flex py-1 mb-4 m-2"><button className="sidebar-button flex items-center justify-center h-10 w-10 rounded-full hover:bg-gray-100"><i className="fa-solid fa-bars text-lg" /></button></div>
              {settingContent === 'info' ?
                <Info {...props} />
                : settingContent === 'account' ?
                  <Account {...props} />
                  : settingContent === 'payments' ?
                    <Payments {...props} />
                    : <></>}
            </main>
          </div>
        </div>
          : <></>}

      {/* mobile sidebar */}
      <div className="sidebar bg-white *:text-start *:text-sm *:mt-2 [&>button]:p-2 [&>button]:px-4 *:rounded-lg rounded-2xl p-4 fixed z-20 md:hidden flex flex-col max-w-96" style={{ transition: 'all .3s', top: '16px', height: 'calc(100vh - 32px)', width: '84vw', left: showSidebar ? '16px' : '-100%', boxShadow: showSidebar ? '0px 0px 1000px 500px #0004' : 'none' }}>
        <div className="flex justify-end"><i onClick={() => setShowSidebar(false)} className="fa-solid fa-times h-8 w-8 flex items-center cursor-pointer justify-center rounded-full hover:bg-gray-100" /></div>
        {SidebarContent}
      </div>
    </>
  )
}

export default Settings;