import { AppProps } from "../App";
import { getFullName } from "../utils/functions";

interface Props extends AppProps {
  imageUrl: string
}

const MenuPersonInfo = (props: Props) => {
  return (
    <div className="flex items-center p-4 cursor-default">
      <div className="rounded-full overflow-hidden w-10 h-10 flex items-center justify-center text-sm text-white bg-neutral-800">
        {props.imageUrl ? <img className="w-full h-full" src={props.imageUrl} /> : props.user?.firstName[0]}
      </div>
      <div className="flex-1 ml-4 overflow-hidden">
        <div>{getFullName(props.user?.firstName || '', props.user?.lastName || '')}</div>
        <div className="font-light text-xs text-neutral-400 text-ellipsis overflow-hidden">{props.user?.email}</div>
      </div>
    </div>
  )
}

export default MenuPersonInfo;