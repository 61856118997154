// local storage
export const storageKeys = {
  id: 'leedto_user_id',
  mode: 'leedto_mode',
}

// database
export const databaseNames = {
  users: 'leedto_users',
  jobs: 'leedto_jobs',
  ratings: 'leedto_ratings',
  bucket: 'leedto-bucket',
}