import { useNavigate } from "react-router-dom";

const TermsOfService = () => {

  const navigate = useNavigate();

  return (
    <main className="flex flex-1 justify-center">
      <div className="policy flex flex-col w-full my-8 p-4 px-8 max-w-3xl [&>p]:mt-1 [&>p]:font-light [&>h2]:text-2xl [&>h2]:font-bold [&>h2]:mt-12 [&>h3]:text-lg [&>h3]:mt-4 [&>h3]:underline">
        <h1 className="text-3xl font-bold">服務條款</h1>
        <p style={{ margin: '1.6rem 0' }}>最後修訂日期：2024 年 10 月 11 日</p>
        <p>歡迎使用 Leedto！<br/>本服務條款管轄你對 Leedto 平台的存取和使用，包括我們的網站（www.leedto.com）和我們提供的任何其他服務（統稱為「Leedto 平台」）。透過造訪或使用平台，表示你同意遵守這些條款。</p>
        
        {/* worker terms */}
        <h2>1. 工作者條款</h2>

        <h3>1.1 自由工作者身份</h3>
        <p>
          Leedto 平台提供的是你與企業短期合作的機會，在雙方同意合作後，<span>並不代表你與企業是雇主與員工的關係</span>，因此企業支付的費用<span>不受限於最低工資法</span>，但你仍可以透過與企業合作的過程，與之協調成為員工。<br/>
          若你與企業在合作過程同意將你視為員工，則企業支付的薪資應遵守<span>最低工資法</span>，你與企業的關係也應遵守其他相關法律。
        </p>
        
        <h3>1.2 工作者責任</h3>
        <p>
          作為工作者，你同意：<br/>
          <ul>
            <li>i. 有任何工作上的疑問或需求，與企業或商店友善地溝通，確保工作流程順利進行</li>
            <li>ii. 和企業的合作成立後，如果要<span>取消</span>，應該主動聯繫企業</li>
            <li>iii. 在未與企業溝通的情況下，不應該擅自缺席，破壞彼此之間的信任</li>
          </ul>
        </p>

        <h3>1.3 取消合作</h3>
        <p>在企業同意你的申請後，不論在開始工作前或在工作期間，若要取消合作，則應該與企業協調，確保雙方同意後才取消。</p>

        {/* business terms */}
        <h2>2. 企業與店家條款</h2>
        
        <h3>2.1 你與工作者的關係</h3>
        <p>在雙方同意合作後，<span>不代表你與工作者是雇主與員工</span>，企業與工作者屬於短期合作的關係，因此支付給工作者的費用不受限於<span>最低工資法</span>，但若是你與工作者在合作過程中，同意將其聘為員工，<span>則應遵守最低工資法與其他相關法律</span>。</p>
        
        <h3>2.2 企業責任</h3>
        <p>
          在你代表企業與工作者合作時，你同意：<br/>
          <ul>
            <li>i. 提供工作者誠實的工作與報酬，避免雙方產生不信任</li>
            <li>ii. 提供清晰且準確的工作描述，包含所有必要的資訊，並隨時與工作者透明地溝通</li>
            <li>iii. 為工作者提供安全、公平的工作環境</li>
          </ul>
        </p>

        <h3>2.3 取消合作和爭議解決</h3>
        <p>
          工作開始後，雙方應保持透明的溝通。若是企業在工作者開始工作前取消合作，則無需支付任何費用給工作者。若是在合作期間取消，則應該與工作者協調，確保雙方同意取消及支付工作者合理的報酬。
        </p>

        <h3>2.4 平台費用</h3>
        <p>目前，Leedto 平台在驗證階段內，不收取企業服務費。你應與工作者協商報酬支付方式，並於工作完成後及時付款。</p>

        {/* general terms */}
        <h2>3. 通用條款</h2>

        <h3>3.1 平台服務</h3>
        <p>Leedto 提供一個連結工作者與企業進行短期合作的服務平台。Leedto 本身不參與雙方之間的任何契約，也不對工作內容或報酬負責。工作者與企業應自行協商條款及條件。</p>

        <h3>3.2 責任限制</h3>
        <p>Leedto 不對使用者之間的任何爭議、工作問題、付款延遲或工作未完成等情況負責。使用者理解並承擔在平台上進行互動的風險。</p>

        <h3>3.3 平台費用</h3>
        <p>目前，Leedto 處於驗證階段，不向工作者或企業收取任何費用。在未來，Leedto 可能會針對平台服務收取費用，具體細節將提前通知使用者。</p>

        <h3>3.4 隱私政策</h3>
        <p>我們尊重你的隱私。請閱讀 Leedto 的<span onClick={() => navigate('/privacy-policy')} className="cursor-pointer underline">隱私政策</span>，以瞭解我們如何收集、使用及保護你的個人資訊。</p>
        
        <h3>3.5 條款修改</h3>
        <p>Leedto 保留隨時修改本服務條款的權利。我們將通過平台公告通知用戶有關條款的變更，變更後繼續使用平台即表示您接受更新的條款。</p>

      </div>
    </main>
  )
}

export default TermsOfService;