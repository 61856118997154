import { config } from "./config";
import { databaseNames } from "./constants";

export const isValidPassword = (password: string) => password.length >= 8 && /\d/.test(password) && /[a-z]/.test(password);

export const randString = (length: number) => Array.from({ length: length }, () => Math.random().toString(36).charAt(2)).join('');

export const getAge = (birthday: string) => {
  const date = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age;
}

export const getDateString = (date: string) => {
  const d = new Date(date);
  return `${d.getFullYear()} / ${d.getMonth() + 1} / ${d.getDate()}`;
}

export const getFullName = (firstName: string, lastName: string) => {
  if (/[a-zA-Z]/g.test(firstName) || /[a-zA-Z]/g.test(lastName)) {
    return firstName + ' ' + lastName;
  }
  return lastName + firstName;
}

// api functions

export const loadImage = async (fileName: string) => {
  const imageData = (await (await fetch(`${config.apiUrl}/s3?bucket=${databaseNames.bucket}&file_name=${fileName}`)).json());
  return `data:${imageData.content_type};base64,${imageData.data}`;
}

export const sendEmail = async (recipients: string[], subject: string, body: string) => {
  try {
    await fetch(`${config.apiUrl}/ses`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
      sender: 'info@leedto.com',
      recipients: recipients,
      subject: subject,
      body: body
    }) });
  } catch (error) {}
}