import React, { useEffect, useState } from "react";
import { AppProps } from "../../App";
import { UserModel } from "../../utils/interfaces";
import { config } from "../../utils/config";
import { databaseNames } from "../../utils/constants";
import { getDateString } from "../../utils/functions";
import UserPicture from "./UserPicture";

const Info = (props: AppProps) => {

  // personal
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingBirthday, setIsEditingBirthday] = useState(false);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [showGenderSelect, setShowGenderSelect] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [bio, setBio] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // business
  const [businessEmail, setBusinessEmail] = useState(props.user?.business?.email || props.user?.email);
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
  const [businessBio, setBusinessBio] = useState('');
  const [isEditingBusinessEmail, setIsEditingBusinessEmail] = useState(false);
  const [isEditingBusinessName, setIsEditingBusinessName] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditingBusinessPhoneNumber, setIsEditingBusinessPhoneNumber] = useState(false);
  const [isEditingBusinessBio, setIsEditingBusinessBio] = useState(false);

  useEffect(() => {
    setFirstName(props.user?.firstName || '');
    setLastName(props.user?.lastName || '');
    setBirthday(props.user?.personalInfo?.birthday || '');
    setBio(props.user?.bio || '');
    setPhoneNumber(props.user?.phoneNumber || '');
  }, [isEditingName, isEditingBio, isEditingBirthday, isEditingPhoneNumber, showGenderSelect]);

  useEffect(() => {
    setBusinessEmail(props.user?.business?.email || props.user?.email);
    setBusinessName(props.user?.business?.name || '');
    setAddress(props.user?.business?.address || '');
    setBusinessPhoneNumber(props.user?.business?.phoneNumber || '');
    setBusinessBio(props.user?.business?.bio || '');
  }, [isEditingBusinessName, isEditingAddress, isEditingBusinessPhoneNumber, isEditingBusinessBio, isEditingBusinessEmail]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.gender-select') && !target.closest('.gender-button')) {
      setShowGenderSelect(false);
    }
  }


  // functions

  const updateUser = async (gender?: string) => {
    if (!props.user) return;
    const newUser: UserModel = {
      ...props.user,
      firstName: firstName,
      lastName: lastName,
      bio: bio,
      phoneNumber: phoneNumber,
      personalInfo: {
        birthday: birthday,
        gender: gender || props.user.personalInfo?.gender,
      },
      business: {
        ...props.user.business,
        email: businessEmail || props.user.email,
        name: businessName,
        bio: businessBio,
        phoneNumber: businessPhoneNumber,
        address: address,
      },
    };
    props.setUser(newUser);
    setShowGenderSelect(false);
    try {
      await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newUser) });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="flex flex-col px-4">
      {/* settings */}
      {props.mode === 'applicant' ? <>

        <h1 className="text-3xl font-bold">個人資料</h1>
        {/* picture & name */}
        <div className="flex items-stretch mt-8">
          <UserPicture {...props} />
          <div className="flex-1 ml-8 flex flex-col justify-center">
            <label className="text-xs text-gray-400 m-1">姓氏</label>
            {isEditingName ? <input className="p-2 text-sm rounded-lg border focus:border-black outline-none" value={lastName} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} /> : <div className="text-lg m-1">{props.user?.lastName}</div>}
            <label className="text-xs text-gray-400 m-1 mt-2">名字</label>
            {isEditingName ? <input className="p-2 text-sm rounded-lg border focus:border-black outline-none" value={firstName} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} /> : <div className="text-lg m-1">{props.user?.firstName}</div>}
            {isEditingName && <div className="mt-4"><button onClick={async () => {
              setIsEditingName(false);
              await updateUser();
            }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
          </div>
          <div className="flex items-start">
            <button onClick={() => setIsEditingName(!isEditingName)} className={"underline ml-4 p-1 text-sm rounded-lg"}>{isEditingName ? '取消' : '編輯'}</button>
          </div>
        </div>
        {/* birthday & gender */}
        <div className="flex flex-col flex-1 mt-8">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">生日</label>
            <button onClick={() => setIsEditingBirthday(!isEditingBirthday)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingBirthday ? '取消' : '編輯'}</button>
          </div>
          <div className="flex items-center">
            {isEditingBirthday ? <input className="flex-1 p-2 text-sm rounded-lg border focus:border-black outline-none" type='date' value={birthday} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setBirthday(e.target.value)} /> : <div className="flex-1 text-lg m-1">{props.user?.personalInfo?.birthday ? getDateString(props.user?.personalInfo?.birthday) : '未設定'}</div>}
          </div>
          {isEditingBirthday && <div className="mt-4"><button onClick={async () => {
            setIsEditingBirthday(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        <div className="flex flex-col flex-1 mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">性別</label>
            <button onClick={() => setShowGenderSelect(!showGenderSelect)} className="gender-button underline ml-4 p-1 text-sm rounded-lg">{showGenderSelect ? '取消' : '編輯'}</button>
          </div>
          <div className="flex items-center relative">
            <button onClick={() => setShowGenderSelect(!showGenderSelect)} className="gender-button m-1 w-full text-start">{props.user?.personalInfo?.gender || '未設定'}</button>
            {showGenderSelect && <div className="gender-select bg-white z-10 w-full flex flex-col absolute top-full shadow rounded-lg border">
              <button onClick={async () => await updateUser('男')} className="p-2 hover:bg-gray-100 text-start px-4">男</button>
              <button onClick={async () => await updateUser('女')} className="p-2 hover:bg-gray-100 text-start px-4">女</button>
            </div>}
          </div>
        </div>
        {/* bio */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">個人簡介</label>
            <button onClick={() => setIsEditingBio(!isEditingBio)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingBio ? '取消' : '編輯'}</button>
          </div>
          {isEditingBio ? <textarea placeholder='向企業介紹你自己...' rows={5} className="w-full flex-1 px-2 text-sm resize-y rounded-lg border focus:border-black outline-none" value={bio} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBio(e.target.value)} /> : <div className="whitespace-pre-wrap m-1">{props.user?.bio || '未設定'}</div>}
          {isEditingBio && <div className="mt-4"><button onClick={async () => {
            setIsEditingBio(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        {/* phone number */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">手機號碼</label>
            <button onClick={() => setIsEditingPhoneNumber(!isEditingPhoneNumber)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingPhoneNumber ? '取消' : '編輯'}</button>
          </div>
          {isEditingPhoneNumber ? <input className="w-full flex-1 px-2 text-sm rounded-lg border focus:border-black outline-none" value={phoneNumber} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)} /> : <div className="mx-1">{props.user?.phoneNumber || '未設定'}</div>}
          {isEditingPhoneNumber && <div className="mt-4"><button onClick={async () => {
            setIsEditingPhoneNumber(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        {/* exp */}
        {/* edu */}
        {/* skills */}

      </> : <>
        {/* business */}

        <h1 className="text-3xl font-bold">企業資料</h1>
        {/* picture & name */}
        <div className="flex items-stretch mt-8">
          <UserPicture isBusiness {...props} />
          <div className="flex-1 ml-8 flex flex-col justify-center">
            <label className="text-xs text-gray-400 m-1">公司或商店名稱</label>
            {isEditingBusinessName ? <input className="p-2 text-sm rounded-lg border focus:border-black outline-none" value={businessName} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setBusinessName(e.target.value)} /> : <div className="text-lg m-1">{props.user?.business?.name || '未設定'}</div>}
            {isEditingBusinessName && <div className="mt-4"><button onClick={async () => {
              setIsEditingBusinessName(false);
              await updateUser();
            }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
          </div>
          <div className="flex items-start">
            <button onClick={() => setIsEditingBusinessName(!isEditingBusinessName)} className={"underline ml-4 p-1 text-sm rounded-lg"}>{isEditingBusinessName ? '取消' : '編輯'}</button>
          </div>
        </div>
        {/* email */}
        <div className="mt-8">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">公司 Email</label>
            <button onClick={() => setIsEditingBusinessEmail(!isEditingBusinessEmail)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingBusinessEmail ? '取消' : '編輯'}</button>
          </div>
          {isEditingBusinessEmail ? <input className="w-full flex-1 px-2 text-sm rounded-lg border focus:border-black outline-none" value={businessEmail} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setBusinessEmail(e.target.value)} /> : <div className="mx-1">{props.user?.business?.email || props.user?.email}</div>}
          {isEditingBusinessEmail && <div className="mt-4"><button onClick={async () => {
            setIsEditingBusinessEmail(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        {/* phone number */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">公司電話號碼</label>
            <button onClick={() => setIsEditingBusinessPhoneNumber(!isEditingBusinessPhoneNumber)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingBusinessPhoneNumber ? '取消' : '編輯'}</button>
          </div>
          {isEditingBusinessPhoneNumber ? <input className="w-full flex-1 px-2 text-sm rounded-lg border focus:border-black outline-none" value={businessPhoneNumber} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setBusinessPhoneNumber(e.target.value)} /> : <div className="mx-1">{props.user?.business?.phoneNumber || '未設定'}</div>}
          {isEditingBusinessPhoneNumber && <div className="mt-4"><button onClick={async () => {
            setIsEditingBusinessPhoneNumber(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        {/* address */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">公司地址</label>
            <button onClick={() => setIsEditingAddress(!isEditingAddress)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingAddress ? '取消' : '編輯'}</button>
          </div>
          {isEditingAddress ? <input className="w-full flex-1 px-2 text-sm rounded-lg border focus:border-black outline-none" value={address} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)} /> : <div className="mx-1">{props.user?.business?.address || '未設定'}</div>}
          {isEditingAddress && <div className="mt-4"><button onClick={async () => {
            setIsEditingAddress(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>
        {/* bio */}
        <div className="mt-4">
          <div className="flex justify-between">
            <label className="text-xs text-gray-400 m-1">公司簡介</label>
            <button onClick={() => setIsEditingBusinessBio(!isEditingBusinessBio)} className="underline ml-4 p-1 text-sm rounded-lg">{isEditingBusinessBio ? '取消' : '編輯'}</button>
          </div>
          {isEditingBusinessBio ? <textarea placeholder='介紹你的企業...' rows={5} className="w-full flex-1 px-2 text-sm resize-y rounded-lg border focus:border-black outline-none" value={businessBio} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBusinessBio(e.target.value)} /> : <div className="whitespace-pre-wrap m-1">{props.user?.business?.bio || '未設定'}</div>}
          {isEditingBusinessBio && <div className="mt-4"><button onClick={async () => {
            setIsEditingBusinessBio(false);
            await updateUser();
          }} className="bg-primary rounded-lg text-white p-2 px-8 text-sm">儲存</button></div>}
        </div>

      </>}
    </div>
  )
}

export default Info;