import { useNavigate } from "react-router-dom";

const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <main className="flex-1 flex flex-col items-center justify-center p-4">
      <h1 className="font-bold text-3xl">哎呀！你要找的頁面不存在。</h1>
      <div onClick={() => navigate('/')} className="text-primary mt-4 underline cursor-pointer">回首頁</div>
    </main>
  )
}

export default PageNotFound;