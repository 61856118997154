import React from "react";
import { getAge, getFullName } from "../utils/functions";
import { UserModel } from "../utils/interfaces";
import { OrgDashboardProps } from "./OrgDashboard";

interface Props extends OrgDashboardProps {
  imageUrl: string
  applicant: UserModel
  setDisplayApplicant: React.Dispatch<React.SetStateAction<UserModel | null>>
}

const ApplicantInfo = (props: Props) => {

  return (
    <div className="applicant-alert bg-white p-4 px-8 overflow-y-auto rounded-xl w-full max-w-3xl shadow-lg" style={{ maxHeight: '80vh' }}>
      <div className="flex justify-end">
        <button onClick={() => props.setDisplayApplicant(null)} className="text-sm rounded-lg hover:bg-gray-100 p-1 px-4">關閉</button>
      </div>
      {/* picture & name */}
      <div className="flex items-center">  
        <div className="rounded-full bg-neutral-800 overflow-hidden text-white flex items-center justify-center text-2xl w-20 h-20">
          {props.imageUrl ? <img className="w-full h-full" src={props.imageUrl} /> : props.applicant.firstName[0]}
        </div>
        <div className="flex ml-8 flex-col">
          <div className="font-bold text-xl">{getFullName(props.applicant.firstName, props.applicant.lastName)}</div>
          <div>{getAge(props.applicant.personalInfo?.birthday || new Date().toISOString())} 歲</div>
          <div>{props.applicant.personalInfo?.gender}</div>
        </div>
      </div>
      <div className="mt-4">
        <label className="text-gray-400 text-sm">個人簡介</label>
        <div className="m-2 whitespace-pre-wrap">{props.applicant.bio}</div>
      </div>
      {/* experience */}
      <div></div>
      {/* education */}
      <div></div>
      {/* skills */}
      <div></div>
      {/* ratings */}
    </div>
  )
}

export default ApplicantInfo;