import { useEffect, useState } from "react";
import { ApplicantDashboardProps, isValidApplicant } from "../ApplicantDashboard";
import { JobModel, UserModel } from "../../utils/interfaces";
import Loading from "../Loading";
import { config } from "../../utils/config";
import { databaseNames } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import BusinessInfo from "../BusinessInfo";
import { getFullName, sendEmail } from "../../utils/functions";

interface Props extends ApplicantDashboardProps {
  id: string
}

const Job = (props: Props) => {

  const navigate = useNavigate();

  const [job, setJob] = useState<undefined | null | JobModel>(undefined);
  const [business, setBusiness] = useState<undefined | null | UserModel>(undefined);
  const [imageUrl, setImageUrl] = useState('');
  const [showApplyAlert, setShowApplyAlert] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [showInvalidApplicantAlert, setShowInvalidApplicantAlert] = useState(false);
  const [showBusinessInfo, setShowBusinessInfo] = useState(false);

  // load job from database
  useEffect(() => {
    (async () => {
      try {
        const data: JobModel = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}&id=${props.id}`)).json();
        setJob(data);
        // get business poster
        const userData = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${data.poster}`)).json();
        setBusiness(userData);
      } catch (error) {
        setJob(null);
        setBusiness(null);
      }
    })();
  }, [props.id]);

  // load image
  useEffect(() => {
    setImageUrl('');
    if (business && business.business?.picture) {
      (async () => {
        try {
          const imageData = (await (await fetch(`${config.apiUrl}/s3?bucket=${databaseNames.bucket}&file_name=${business.business?.picture}`)).json());
          setImageUrl(`data:${imageData.content_type};base64,${imageData.data}`);
        } catch (error) {}
      })();
    }
  }, [business]);

  // redirect if job not exists or user is not poster
  useEffect(() => {
    if (job === null) {
      navigate('/page-not-found');
      return;
    }
  }, [job]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.apply-alert') && !target.closest('.apply-button')) {
      setShowApplyAlert(false);
    }
    if (!target.closest('.invalid-applicant-alert') && !target.closest('.apply-button')) {
      setShowInvalidApplicantAlert(false);
    }
    if (!target.closest('.business-info-button') && !target.closest('.business-info')) {
      setShowBusinessInfo(false);
    }
  }

  // apply job
  const applyJob = async () => {
    if (!job || !props.user || !business) return;

    // check is valid applicant
    if (!isValidApplicant(props.user)) {
      setShowInvalidApplicantAlert(true);
      return;
    }

    setIsApplying(true);
    const newUser: UserModel = { ...props.user, applys: [...(props.user.applys || []), job.id] };
    const newJob: JobModel = { ...job, applicants: [...job.applicants, newUser.id] };
    props.setUser(newUser);
    setJob(newJob);
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newUser) });
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.jobs}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newJob) });
    setIsApplying(false);
    setShowApplyAlert(true);
    // send email to poster
    const message = `
Hi，${business.firstName}，您在 Leedto 上發佈的 ${job.title} 職位已經收到新的申請。以下是申請人的詳細資料：

申請人姓名： ${getFullName(props.user.firstName, props.user.lastName)}
聯絡方式： ${props.user.email}

如果有任何疑問，請隨時與我們聯絡。

再次感謝您選擇 Leedto，我們期待與你一同展開新挑戰！

網址：https://www.leedto.com

Leedto 團隊
info@leedto.com
`;
    await sendEmail([business.email], '新申請通知：有人已申請您發佈的工作', message);
  }

  return (
    <>
      {(job === undefined) ? <div className="flex-1 flex items-center justify-center">
        <Loading solid />
      </div>
        : job ? <>
          {/* header */}
          <div className="flex items-center">
            <i onClick={() => navigate('/')} className="fa-solid fa-arrow-left h-10 w-10 flex items-center justify-center hover:bg-gray-100 rounded-full cursor-pointer" />
            <div className="text-3xl font-bold ml-8">工作資訊</div>
          </div>
          {/* info */}
          <div className="mt-4 flex flex-col md:flex-row">
            <div className="flex-1 flex flex-col">
              <label className="text-xs text-gray-400 m-1">職位名稱</label>
              <div className="m-1">{job.title}</div>
            </div>
            <div className="mt-2 md:mt-0 md:ml-4 flex-1 flex flex-col">
              <label className="text-xs text-gray-400 m-1">公司</label>
              <div onClick={() => setShowBusinessInfo(true)} className="business-info-button cursor-pointer m-1">{job.posterName}</div>
            </div>
          </div>
          <div className="mt-2 flex flex-col">
            <label className="text-xs text-gray-400 m-1">報酬</label>
            <div className="m-1">{job.reward}</div>
          </div>
          <div className="mt-2 flex flex-col md:flex-row">
            <div className="flex-1 flex flex-col">
              <label className="text-xs text-gray-400 m-1">工作期間</label>
              <div className="m-1">{job.duration}</div>
            </div>
            <div className="mt-2 md:mt-0 md:ml-4 flex-1 flex flex-col">
              <label className="text-xs text-gray-400 m-1">時間</label>
              <div className="m-1">{job.time}</div>
            </div>
          </div>
          <div className="mt-2 flex flex-col">
            <label className="text-xs text-gray-400 m-1">工作內容</label>
            <div className="m-1 whitespace-pre-wrap">{job.description}</div>
          </div>
          <div className="mt-2 flex flex-col">
            <label className="text-xs text-gray-400 m-1">地址</label>
            <div className="m-1">{job.address}</div>
          </div>
          <div className="mt-2 flex flex-col">
            <label className="text-xs text-gray-400 m-1">應徵人數 / 需求人數</label>
            <div className="m-1">{job.applicants.length} / {job.applicantsAmount}</div>
          </div>
          {props.user && <div className="mt-8">
            {job.applicants.includes(props.user.id) ?
              <div className="text-gray-400">{job.accepted?.includes(props.user.id) ? '你的應徵已被接受' : '你已應徵此工作'}</div>
            : 
              <button onClick={applyJob} className="apply-button flex items-center justify-center disabled:bg-gray-300 bg-primary text-white rounded-lg p-2 px-8" disabled={isApplying}>{isApplying ? <Loading /> : '應徵'}</button>}
          </div>}
        </>
          : <></>}

      {/* apply alert */}
      {showApplyAlert && <div className="fixed top-0 left-0 w-full h-full p-4 bg-black/10 z-20 flex items-center justify-center">
        <div className="apply-alert bg-white w-full p-4 px-8 max-w-md rounded-xl shadow">
          <h1 className="text-xl font-bold">應徵成功</h1>
          <p className="mt-2">成功應徵 <span className="font-bold">{job?.posterName}</span> 發布的工作機會，當企業接受後即可上工。</p>
          <div className="flex justify-end mt-8">
            <button onClick={() => setShowApplyAlert(false)} className="text-sm bg-primary text-white rounded-lg p-2 px-8">好</button>
          </div>
        </div>
      </div>}

      {/* block invalid applicant */}
      {showInvalidApplicantAlert && <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black/10 z-20">
        <div className="invalid-applicant-alert rounded-xl bg-white shadow-lg p-4 px-8 w-full max-w-xl">
          <h1 className="text-xl font-bold">完成個人資料</h1>
          <p className="font-light mt-4">你還沒有完成個人資料設定。</p>
          <div className="mt-8 flex justify-center"><button onClick={() => navigate('/settings/info')} className="text-sm bg-primary p-2 px-8 rounded-lg text-white">前往設定</button></div>
        </div>
      </div>}

      {/* business info */}
      {(showBusinessInfo && business) && <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black/10 z-20">
        <BusinessInfo imageUrl={imageUrl} business={business} setShowBusinessInfo={setShowBusinessInfo} {...props} />
      </div>}
    </>
  )
}

export default Job;