const Logo = () => {
  return (
    <svg height="36" viewBox="0 0 219 190" fill="none">
      <path d="M128.126 170.511C132.534 175.107 140.66 178.553 147.271 180.085C153.883 181.617 159.499 180.851 164.008 180.085C168.517 179.319 177.464 175.583 183.205 165.933C186.624 160.188 191.529 148.351 191.529 132.612C191.529 116.873 182.165 96.7937 167.945 87.8014C153.725 78.809 137.077 74.7794 137.077 74.7794C137.077 74.7794 123.25 52.1784 105.252 47.9659C87.2537 43.7534 67.4539 48.3295 57.6528 58.3104C47.8517 68.2912 43.3378 77.8434 41.4676 92.9506C39.5975 108.058 39.7208 118.675 45 123.5C50.2792 128.325 57.8028 128.397 61.7232 126.5C65.6436 124.603 68.584 120.59 68.584 114.064C68.584 107.538 63.6834 99.0926 53.5556 98.7087C43.4279 98.3249 29.3796 104.083 19.9053 114.064C10.4309 124.045 7.633 136.404 8.03748 146.385C8.44197 156.366 13.3712 166.669 19.9053 172.043C26.4393 177.417 35.414 180.085 43.6472 180.085C51.8804 180.085 55.3135 177.405 61.7232 176.256C61.7232 176.256 81.7019 182 94.9252 182C119.311 182 128.126 170.511 128.126 170.511ZM128.126 170.511C123.719 165.916 120.895 161.526 119.311 158.257C116.269 151.98 113.992 142.924 113.992 129.799" stroke="#6699EE" strokeWidth="16" />
      <path d="M114 130C114 130 114.47 114 117.5 104C120.53 94 128 80 135 72C142 64 156.5 49.5 165 43.5C173.5 37.5 177.5 35.5 177.5 35.5" stroke="#6699EE" stroke-width="16" stroke-linecap="round" />
      <path d="M168.388 9.13174C167.955 0.326573 213.671 10.2032 216.722 18.1274C219.772 26.0516 199.24 57.7823 193.776 57.7371C188.312 57.6919 198.685 29.6824 197.998 24.2604C197.312 18.8383 168.821 17.9369 168.388 9.13174Z" fill="#6699EE" />
    </svg>
  )
}

export default Logo;