import React, { useEffect, useRef, useState } from "react";
import { AppProps } from "../../App";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css'
import { config } from "../../utils/config";
import { databaseNames } from "../../utils/constants";
import { loadImage } from "../../utils/functions";

interface Props extends AppProps {
  isBusiness?: boolean
}

const UserPicture = ({ isBusiness, ...props }: Props) => {

  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [originalSrc, setOriginalSrc] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const [imgType, setImgType] = useState('');
  const [contentType, setContentType] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  // get user image
  useEffect(() => {
    setImageUrl('');
    (async () => {
      if (props.mode === 'applicant' && !props.user?.picture) return;
      if (props.mode === 'organization' && !props.user?.business?.picture) return;
      try {
        const imageData = await loadImage((props.mode === 'applicant' ? props.user?.picture : props.user?.business?.picture) || '');
        setImageUrl(imageData);
      } catch (error) {}
    })();
  }, [props.mode, props.user?.picture, props.user?.business?.picture]);
  
  // file change
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setOriginalSrc(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
      setImgType(e.target.files[0].name.split('.').pop() || '');
      setContentType(e.target.files[0].type);
    }
  }

  // on image load
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const crop = makeAspectCrop({
      unit: '%',
      width: 100,
    }, 1, width, height);
    setCrop(centerCrop(crop, width, height));
  }

  // save the image to bucket
  const saveImage = async () => {
    if (!props.user) return;
    setIsSaving(true);
    if (imageRef.current && crop?.width && crop.height) {
      const url = await getCroppedImage(imageRef.current, crop);
      let newUser;
      if (props.mode === 'applicant') {
        newUser = { ...props.user, picture: `${props.user.id}.${imgType}` };
      } else {
        newUser = { ...props.user, business: { ...(props.user.business || {}), picture: `${props.user.id}_business.${imgType}` } };
      }
      try {
        // save image
        await fetch(`${config.apiUrl}/s3?bucket=${databaseNames.bucket}&content_type=${contentType}&file_name=${`${props.user.id}${props.mode === 'applicant' ? '' : '_business'}.${imgType}`}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
          data: url.split(',')[1]
        }) });
        // update user
        await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newUser) });
        props.setUser(newUser);
        setImageUrl(url);
      } catch (error) {}
    }
    setOriginalSrc('');
    setIsSaving(false);
  }

  // get image url from image element and crop
  const getCroppedImage = (img: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const cropX = (crop.x! / 100) * img.naturalWidth;
    const cropY = (crop.y! / 100) * img.naturalHeight;
    const cropWidth = (crop.width! / 100) * img.naturalWidth;
    const cropHeight = (crop.height! / 100) * img.naturalHeight;
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    
    if (ctx) {
      ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    }
    
    return Promise.resolve(canvas.toDataURL("image/jpeg"));
  }

  return (
    <>
      {(isBusiness) ?
        <div onClick={() => (inputRef.current as any as HTMLElement).click()} className="image-button cursor-pointer rounded-full overflow-hidden h-20 w-20 md:h-32 md:w-32 bg-neutral-800">
          {(props.user?.business?.picture && imageUrl) ?
            <img className="w-full h-full" src={imageUrl} />
            : <div className="w-full h-full flex items-center justify-center text-5xl text-white/20"><i className="fa-regular fa-image" /></div>}
        </div>
        :
        <div onClick={() => (inputRef.current as any as HTMLElement).click()} className="image-button cursor-pointer rounded-full overflow-hidden h-20 w-20 md:h-32 md:w-32 bg-neutral-800">
          {(props.user?.picture && imageUrl) ?
            <img className="w-full h-full" src={imageUrl} />
            : <div className="w-full h-full flex items-center justify-center text-4xl text-white">{props.user?.firstName[0]}</div>}
        </div>}

      <input ref={inputRef} type="file" className="hidden" accept="image/*" onChange={onFileChange} />

      {/* image cropper */}
      {originalSrc && <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black/10 z-20">
        <div className="image-cropper rounded-xl bg-white overflow-auto shadow-lg p-4 px-8 w-full max-w-2xl" style={{ maxHeight: '90vh' }}>
          <h1 className="text-xl font-bold">編輯相片</h1>
          <div className="w-full px-4 aspect-square flex items-center justify-center">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              circularCrop
              keepSelection
              aspect={1}
              minWidth={128}
            ><img src={originalSrc} ref={imageRef} alt="" onLoad={onImageLoad} /></ReactCrop>
          </div>
          <div className="flex justify-end mt-4">
            <button onClick={saveImage} className="bg-primary text-white p-2 px-8 text-sm rounded-lg disabled:bg-gray-300" disabled={isSaving}>儲存</button>
            <button onClick={() => setOriginalSrc('')} className="bg-gray-100 p-2 px-8 text-sm rounded-lg ml-4">取消</button>
          </div>
        </div>
      </div>}
    </>
  )
}

export default UserPicture;