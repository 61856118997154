import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate();

  return (
    <footer className="flex p-4 justify-center items-center">
      <div className="flex items-center text-sm">
        <div className="text-gray-400">2024 Leedto</div>
        <div className="h-4 mx-3 bg-gray-400" style={{ width: 1 }}></div>
        <div onClick={() => navigate('/privacy-policy')} className="text-gray-400 hover:underline cursor-pointer">隱私政策</div>
        <div className="h-4 mx-3 bg-gray-400" style={{ width: 1 }}></div>
        <div onClick={() => navigate('/terms-of-service')} className="text-gray-400 hover:underline cursor-pointer">服務條款</div>
      </div>
    </footer>
  )
}

export default Footer;