import { AppProps } from "../App";
import OrgDashboard from "../components/OrgDashboard";
import ApplicantDashboard from "../components/ApplicantDashboard";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const Home = (props: AppProps) => {

  const navigate = useNavigate();
  const location = useLocation();

  // change mode based on route
  useEffect(() => {
    if (location.pathname.startsWith('/jobs/')) {
      props.setMode('organization');
      localStorage.setItem(storageKeys.mode, 'organization');
    } else if (location.pathname.startsWith('/apply/')) {
      props.setMode('applicant');
      localStorage.setItem(storageKeys.mode, 'applicant');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (props.user === null) {
      navigate('/login');
    }
  }, [props.user]);

  return (
    <div className="flex-1 flex px-4 justify-center w-full overflow-hidden">
      {props.mode === 'applicant' ? <ApplicantDashboard {...props} /> : <OrgDashboard {...props} />}
    </div>
  )
}

export default Home;