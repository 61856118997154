import React, { useEffect, useRef, useState } from "react";
import { AppProps } from "../../App";
import { config } from "../../utils/config";
import Loading from "../Loading";

const Account = (props: AppProps) => {

  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReseting, setIsReseting] = useState(false);
  const [isInvalidUser, setIsInvalidUser] = useState(false);

  useEffect(() => {
    setCode('');
    setNewPassword('');
    setConfirmPassword('');
  }, [showResetPasswordForm]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.reset-password-button') && !target.closest('.reset-password-form')) {
      setShowResetPasswordForm(false);
    }
  }

  // start reset password
  const startResetPassword = async () => {
    setIsInvalidUser(false);
    setShowResetPasswordForm(true);
    // get user and check if exists in userpool
    try {
      await fetch(`${config.apiUrl}/cognito?action=reset_password&username=${props.user?.email}`);
    } catch (error) {
      // user doesn't exist
      setIsInvalidUser(true);
    }
  }

  // reset password
  const resetPassword = async () => {
    setErrorMessage('');
    setIsReseting(true);
    try {
      await fetch(`${config.apiUrl}/cognito?action=confirm_reset_password&username=${props.user?.email}&code=${code}&password=${newPassword}`);
    } catch (error) {
      setErrorMessage('驗證碼不正確');
    }
    setShowResetPasswordForm(false);
    setIsReseting(false);
  }

  return (
    <>
      <div className="flex flex-col px-4">
        <h1 className="text-3xl font-bold">帳號</h1>
        <div className="mt-8">
          <div className="text-xl font-bold">密碼</div>
          <div className="mt-2 flex justify-between">
            <p className="font-light mt-2">接收驗證碼並重設你的密碼。</p>
            <button onClick={startResetPassword} className="reset-password-button text-sm underline p-1 ml-4">重設密碼</button>
          </div>
        </div>
      </div>

      {/* reset password */}
      {showResetPasswordForm && <div className="fixed flex justify-center items-center top-0 left-0 w-full h-full bg-black/10 z-20">
        <div className="reset-password-form rounded-xl bg-white shadow-lg p-4 px-8 w-full max-w-xl">
          <h1 className="text-xl font-bold">重設密碼</h1>
          <p className="mt-4 font-light text-sm">{isInvalidUser ? '你先前已使用 Google 登入，使用相同 Email 註冊新帳號以新增密碼' : `我們已經發送驗證碼到 ${props.user?.email}，輸入驗證碼來設定密碼。`}</p>
          {errorMessage && <div className="text-center font-light mt-4 text-sm text-red-500">{errorMessage}</div>}
          {!isInvalidUser && <>
            <div className="flex flex-col mt-4">
              <label className="text-gray-400 m-1 text-xs">驗證碼</label>
              <input className="text-sm border focus:border-black rounded-lg p-2 outline-none" value={code} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)} />
            </div>
            <div className="flex flex-col mt-2">
              <label className="text-gray-400 m-1 text-xs">新密碼</label>
              <div className="w-full relative">
                <input ref={newPasswordRef} type={showNewPassword ? 'text' : 'password'} className="pr-12 border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={newPassword} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)} />
                <div onClick={() => {
                  setShowNewPassword(!showNewPassword);
                  (newPasswordRef.current as any as HTMLElement).focus();
                }} className="absolute top-1/2 -translate-y-1/2 right-4 text-xs hover:underline cursor-pointer">{showNewPassword ? '隱藏' : '顯示'}</div>
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <label className="text-gray-400 m-1 text-xs">確認新密碼</label>
              <div className="w-full relative">
                <input ref={confirmPasswordRef} type={showConfirmPassword ? 'text' : 'password'} className="pr-12 border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={confirmPassword} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)} />
                <div onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                  (confirmPasswordRef.current as any as HTMLElement).focus();
                }} className="absolute top-1/2 -translate-y-1/2 right-4 text-xs hover:underline cursor-pointer">{showConfirmPassword ? '隱藏' : '顯示'}</div>
              </div>
            </div>
            <div className="flex justify-end mt-8">
              <button onClick={resetPassword} className="flex items-center justify-center text-sm bg-primary rounded-lg text-white p-2 px-8 disabled:bg-gray-300" disabled={code === '' || newPassword.length < 8 || confirmPassword !== newPassword || isReseting}>{'繼續'}</button>
              <button onClick={() => setShowResetPasswordForm(false)} className="text-sm bg-gray-100 rounded-lg ml-4 p-2 px-8">取消</button>
            </div>
          </>
          }
        </div>
      </div>}
    </>
  )
}

export default Account;