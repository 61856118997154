import { useNavigate } from "react-router-dom";
import { AppProps } from "../App";
import React, { useEffect, useRef, useState } from "react";
import { config } from "../utils/config";
import { isValidPassword } from "../utils/functions";
import { UserModel } from "../utils/interfaces";
import { databaseNames, storageKeys } from "../utils/constants";
import Loading from "../components/Loading";
import { useGoogleLogin } from "@react-oauth/google";

const SignIn = (props: AppProps) => {

  const navigate = useNavigate();
  // refs
  const signUpPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const passwordRef = useRef(null);

  // sign in
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);

  // sign up
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSignUpPassword, setShowSignUpPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState('');

  // verify
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [code, setCode] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);

  // redirect
  useEffect(() => {
    if (props.user) {
      navigate('/');
    }
  }, [props.user]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    setFirstName('');
    setLastName('');
    setSignUpEmail('');
    setSignUpPassword('');
    setConfirmPassword('');
    setCode('');
    setShowSignUpPassword(false);
    setShowConfirmPassword(false);
  }, [showSignUpForm]);

  // Event listeners
  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.sign-up-form') && !target.closest('.sign-up-button')) {
      setShowSignUpForm(false);
      setHasSignedUp(false);
    }
  }

  // auth functions

  const handleSignIn = async () => {
    setIsSigningIn(true);
    setErrorMessage('');
    // handle sign in
    try {
      await fetch(`${config.apiUrl}/cognito?action=sign_in&username=${email}&password=${password}`, { 'headers': { 'Content-Type': 'application/json' } });
      await getUser(email);
      navigate('/');
    } catch (error: any) {
      setErrorMessage('Email 或密碼不正確');
    }
    setIsSigningIn(false);
  }

  const handleSignUp = async () => {
    setIsSigningUp(true);
    setSignUpErrorMessage('');
    // send verification code
    try {
      await fetch(`${config.apiUrl}/cognito?action=sign_up&username=${signUpEmail}&password=${signUpPassword}&email=${signUpEmail}`, { 'headers': { 'Content-Type': 'application/json' } });
      setHasSignedUp(true);
    } catch (error: any) {
      setSignUpErrorMessage('Email 已被使用');
    }
    setIsSigningUp(false);
  }

  const handleConfirmSignUp = async () => {
    setIsConfirming(true);
    setSignUpErrorMessage('');
    let wrongCode = false;
    // verify
    try {
      await fetch(`${config.apiUrl}/cognito?action=verify&username=${signUpEmail}&code=${code}`);
    } catch (error) {
      setSignUpErrorMessage('驗證碼錯誤');
      wrongCode = true;
    }
    try {
      if (!wrongCode) {
        await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${signUpEmail}`)).json();
      }
    } catch (error) {
      // create new user
      await storeNewUser(signUpEmail, signUpEmail, firstName, lastName);
    }
    if (!wrongCode) {
      setShowSignUpForm(false);
      setHasSignedUp(false);
    }
    setIsConfirming(false);
  }

  // google login
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      setIsSigningIn(true);
      let [email, firstName, lastName] = ['', '', ''];
      // get user info from google
      try {
        const response = await (await fetch('https://www.googleapis.com/oauth2/v3/userinfo', { headers: {
          "Authorization": `Bearer ${tokenResponse.access_token}`
        }})).json();
        email = response.email;
        firstName = response.given_name || '';
        lastName = response.family_name || '';
      } catch (err) {
        console.log(err)
      }
      // check if user exists in database
      try {
        await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${email}`)).json();
      } catch (error) {
        // create new user
        if (email.length !== 0) {
          await storeNewUser(email, email, firstName, lastName);
        }
      }
      // get user and go to home page
      await getUser(email);
      navigate('/');
    }
  })

  // store a new user to database
  const storeNewUser = async (id: string, email: string, firstName: string, lastName: string) => {
    const newUser: UserModel = { id: id, email: email, firstName: firstName, lastName: lastName, phoneNumber: '' };
    await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}`, { method: 'POST', body: JSON.stringify(newUser) });
  }

  // get user from database
  const getUser = async (id: string) => {
    const data = await (await fetch(`${config.apiUrl}/dynamo?table=${databaseNames.users}&id=${id}`)).json();
    props.setUser(data);
    localStorage.setItem(storageKeys.id, id);
  }

  return (
    <>
      <div className="flex items-center justify-center p-4 py-10">
        <div className="flex flex-col items-center w-full max-w-lg p-8">
          <h1 className="text-4xl mt-8">登入</h1>
          {errorMessage && <div className="text-red-500 text-center mt-4 text-sm">{errorMessage}</div>}
          <div className="flex flex-col w-full mt-4">
            <label className="m-1">Email</label>
            <input className="border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={email} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
          </div>
          <div className="flex flex-col w-full mt-2">
            <label className="m-1">密碼</label>
            <div className="w-full relative">
              <input ref={passwordRef} type={showPassword ? 'text' : 'password'} className="pr-12 border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={password} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} />
              <div onClick={() => {
                setShowPassword(!showPassword);
                (passwordRef.current as any as HTMLElement).focus();
              }} className="absolute top-1/2 -translate-y-1/2 right-4 text-xs hover:underline cursor-pointer">{showPassword ? '隱藏' : '顯示'}</div>
            </div>
          </div>
          <button onClick={handleSignIn} className="mt-8 flex items-center justify-center bg-primary p-2 w-full text-white rounded-lg disabled:bg-gray-300" disabled={email.length === 0 || password.length === 0 || isSigningIn}>{isSigningIn ? <Loading /> : '登入'}</button>

          <div className="w-full bg-gray-300 my-8 relative" style={{ height: 1 }}>
            <div className="text-gray-300 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-sm bg-white px-2">或</div>
          </div>

          {/* google login */}
          <button onClick={() => googleLogin()} className="border border-gray-300 rounded-lg p-2 w-full flex items-center justify-center"><i className="fa-brands fa-google mr-4" />使用 Google 繼續</button>

          {/* sign up button */}
          <div onClick={() => setShowSignUpForm(true)} className="sign-up-button mt-8 text-sm cursor-pointer hover:underline">還沒有帳號嗎？註冊</div>

        </div>
      </div>

      {/* sign up form */}
      {showSignUpForm && <div className="bg-black/10 p-4 fixed w-full h-full flex items-center justify-center top-0 left-0 z-20">
        <div className="sign-up-form max-w-lg w-full p-8 py-6 rounded-xl bg-white shadow-lg" style={{ maxHeight: '80%' }}>
          <div className="flex justify-between">
            <h1 className="text-2xl">註冊</h1>
            <i onClick={() => {
              setShowSignUpForm(false);
              setHasSignedUp(false);
            }} className="w-7 h-7 flex items-center justify-center rounded-full cursor-pointer hover:bg-gray-100 fa-solid fa-times" />
          </div>
          {signUpErrorMessage && <div className="text-red-500 text-center mt-4 text-sm">{signUpErrorMessage}</div>}
          {!hasSignedUp ? <>
            {/* sign up inputs */}
            <div className="flex mt-4">
              <div className="flex-1 flex flex-col">
                <label className="m-1">姓氏</label>
                <input className="border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={lastName} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
              </div>
              <div className="flex-1 flex flex-col ml-2">
                <label className="m-1">名字</label>
                <input className="border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={firstName} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
              </div>
            </div>
            <div className="mt-4">
              <label className="m-1">Email</label>
              <input className="border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={signUpEmail} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSignUpEmail(e.target.value)} />
            </div>
            <div className="mt-4">
              <label className="m-1">密碼 <span className="text-xs">(至少 8 個字元，包含數字與小寫字母)</span></label>
              <div className="w-full relative">
                <input ref={signUpPasswordRef} type={showSignUpPassword ? 'text' : 'password'} className="pr-12 border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={signUpPassword} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSignUpPassword(e.target.value)} />
                <div onClick={() => {
                  setShowSignUpPassword(!showSignUpPassword);
                  (signUpPasswordRef.current as any as HTMLElement).focus();
                }} className="absolute top-1/2 -translate-y-1/2 right-4 text-xs hover:underline cursor-pointer">{showSignUpPassword ? '隱藏' : '顯示'}</div>
              </div>
            </div>
            <div className="mt-4">
              <label className="m-1">確認密碼</label>
              <div className="w-full relative">
                <input ref={confirmPasswordRef} type={showConfirmPassword ? 'text' : 'password'} className="pr-12 border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={confirmPassword} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)} />
                <div onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                  (confirmPasswordRef.current as any as HTMLElement).focus();
                }} className="absolute top-1/2 -translate-y-1/2 right-4 text-xs hover:underline cursor-pointer">{showConfirmPassword ? '隱藏' : '顯示'}</div>
              </div>
            </div>
            <button onClick={handleSignUp} className="mt-8 flex items-center justify-center bg-primary p-2 w-full text-white rounded-lg disabled:bg-gray-300" disabled={firstName.length === 0 || lastName.length === 0 || signUpEmail.length === 0 || !isValidPassword(signUpPassword) || confirmPassword !== signUpPassword || isSigningUp}>{isSigningUp ? <Loading /> : '註冊'}</button>
          </> : <>
            {/* verify code input */}
            <div className="mt-4">
              <label className="m-1">Email 驗證碼</label>
              <input className="border border-gray-200 outline-none focus:border-black rounded-lg p-2 w-full" value={code} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)} />
            </div>
            <button onClick={handleConfirmSignUp} className="mt-8 flex items-center justify-center bg-primary p-2 w-full text-white rounded-lg disabled:bg-gray-300" disabled={code.length === 0 || isConfirming}>{isConfirming ? <Loading /> : '確認'}</button>
          </>}
        </div>
      </div>}
    </>
  )
}

export default SignIn;