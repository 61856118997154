import { useEffect, useState } from "react";
import { AppProps } from "../App";
import Jobs from "./ApplicantDashboard_components/Jobs";
import Job from "./ApplicantDashboard_components/Job";
import { useLocation, useNavigate } from "react-router-dom";
import { UserModel } from "../utils/interfaces";

export type JobType = 'all';

export interface AreaModel {
  country: 'Taiwan',
  city: 'all' | 'Taipei' | 'New Taipei' | 'Taoyuan' | 'Taichung' | 'Tainan' | 'Kaohsiung' | 'Keelung' | 'Hsinchu' | 'Chiayi' | 'Yilan' | 'Hualien' | 'Taitung' | 'Penghu' | 'Kinmen' | 'Lienchiang',
  region: '',
}

type MainContentType = 'jobs' | 'apply' | 'organization' | 'message' | 'ratings'

const MainContentTypeNames = {
  'jobs': '工作機會',
  'apply': '工作資訊',
  'organization': '企業資訊',
  'message': '訊息',
  'ratings': '企業評價',
}

export interface ApplicantDashboardProps extends AppProps {
  mainContentType: MainContentType
}


const ApplicantDashboard = (props: AppProps) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [jobType, setJobType] = useState<JobType>('all');
  const [area, setArea] = useState<AreaModel>({ country: 'Taiwan', city: 'all', region: '' });
  const [showSidebar, setShowSidebar] = useState(false);

  const [mainContentType, setMainContentType] = useState<MainContentType>('jobs');
  const dashboardProps = {
    ...props,
    mainContentType,
  }

  // check route type
  useEffect(() => {
    // job details
    if (location.pathname === '/') {
      setMainContentType('jobs');
    } else if (location.pathname.startsWith('/apply/')) {
      setMainContentType('apply');
    }
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.sidebar-button') && !target.closest('.sidebar')) {
      setShowSidebar(false);
    }
  }

  const SidebarContent = <>
    <label className="text-xs text-gray-500">工作類型</label>
    <button onClick={() => {
      navigate('/');
      setShowSidebar(false);
    }} className={`${jobType === 'all' ? 'bg-gray-200' : ''} text-sm mt-2 text-start p-2 px-4`}>所有類型</button>
    <label className="text-xs text-gray-500 mt-4">地區</label>
    <button onClick={() => {
      navigate('/');
      setShowSidebar(false);
    }} className={`${area.city === 'all' ? 'bg-gray-200' : ''} text-sm mt-2 text-start p-2 px-4`}>所有地區</button>
  </>

  return (
    <>
      <div className="w-full h-full flex flex-1 overflow-hidden">
        <aside className="sticky top-0 self-start h-full z-0 hidden md:flex flex-col w-80 rounded-2xl bg-gray-100 p-4 *:rounded-lg">
          {SidebarContent}
        </aside>
        <main className="flex-1 md:ml-8 px-2 md:px-4 md:py-4 flex flex-col h-full w-full md:w-auto overflow-auto">
          <div onClick={() => setShowSidebar(true)} className="md:hidden flex py-1"><button className="sidebar-button flex items-center justify-center h-10 w-10 rounded-full hover:bg-gray-100"><i className="fa-solid fa-bars text-lg" /></button></div>
          {mainContentType === 'jobs' && <h1 className="md:mt-0 mt-4 text-3xl font-bold">{MainContentTypeNames[mainContentType]}</h1>}
          {mainContentType === 'jobs' ?
            <Jobs {...dashboardProps} />
            : mainContentType === 'apply' ?
              <Job id={location.pathname.slice(7)} {...dashboardProps} />
              : <></>}
        </main>
      </div>

      {/* mobile sidebar */}
      <div className="sidebar bg-gray-100 *:rounded-lg rounded-2xl p-4 fixed z-20 md:hidden flex flex-col max-w-96" style={{ transition: 'all .3s', top: '16px', height: 'calc(100vh - 32px)', width: '84vw', left: showSidebar ? '16px' : '-100%', boxShadow: showSidebar ? '0px 0px 1000px 500px #0004' : 'none' }}>
        <div className="flex justify-end"><i onClick={() => setShowSidebar(false)} className="fa-solid fa-times h-8 w-8 flex items-center cursor-pointer justify-center rounded-full hover:bg-gray-200" /></div>
        {SidebarContent}
      </div>
    </>
  )
}

export const isValidApplicant = (user: UserModel) => {
  return (
    user.phoneNumber &&
    user.bio &&
    user.picture &&
    user.personalInfo?.birthday &&
    user.personalInfo.gender
  )
}

export default ApplicantDashboard;