import React, { useEffect, useState } from "react";
import { JobModel } from "../../utils/interfaces";
import Loading from "../Loading";
import { config } from "../../utils/config";
import { databaseNames } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ApplicantDashboardProps } from "../ApplicantDashboard";

const Jobs = (props: ApplicantDashboardProps) => {

  const navigate = useNavigate();
  const [jobs, setJobs] = useState<JobModel[]>([]);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);

  // load the jobs
  useEffect(() => {
    (async () => {
      const data = await (await fetch(`${config.apiUrl}/dynamo?action=scan&table=${databaseNames.jobs}`, { headers: { 'Content-Type': 'application/json' } })).json();
      setJobs(data);
      setIsLoadingJobs(false);
    })();
  }, []);

  return (
    isLoadingJobs ? <div className="flex-1 flex items-center justify-center">
      <Loading solid />
    </div>
      : <>
        <div className="flex justify-between items-start">
          <div className="text-gray-400 my-2 font-light">{jobs.length} 個工作機會</div>
        </div>
        {(jobs.length === 0) ? <div className="flex-1 flex items-center justify-center">
          <div>目前沒有企業發布任何工作機會</div>
        </div> : <div className="flex flex-wrap mt-4 w-full">
          {jobs.map((job, i) => {
            return (
              <div onClick={() => navigate(`/apply/${job.id}`)} className="w-full md:w-1/3 overflow-hidden m-4 cursor-pointer p-2 px-4 hover:bg-gray-100 rounded-lg">
                <div className="flex justify-between items-center">
                  <div className="font-bold text-ellipsis overflow-hidden text-nowrap">{job.title}</div>
                  <div className="text-xs text-gray-400 text-ellipsis overflow-hidden text-nowrap ml-2" style={{ maxWidth: '50%' }}>{job.posterName}</div>
                </div>
                <div className="text-xs mt-0.5 text-gray-500 text-nowrap overflow-hidden text-ellipsis">{job.address}</div>
                <div className="text-xs mt-0.5 text-gray-500 text-nowrap overflow-hidden text-ellipsis">工作期間：{job.duration}</div>
                <div className="text-xs mt-0.5 text-gray-500 text-nowrap overflow-hidden text-ellipsis">{job.time}</div>
                <div className="text-xs mt-0.5 text-gray-500 text-nowrap overflow-hidden text-ellipsis">{job.description}</div>
                <div className="text-sm mt-0.5 text-nowrap overflow-hidden text-ellipsis">{job.reward}</div>
              </div>
            )
          })}
        </div>}
      </>
  )
}

export default Jobs;